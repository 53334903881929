import MouseEvent from './Input/MouseListener'
import KeyEvent from './Input/KeyListener'
import FSM from './StateMachine'
import Map from './Map'

import GLR from './GL/GLRenderer';

class Game {
	constructor() {
		this.map = new Map();

		this.showGrid = true;
		this.zoom = 1;
		this.xOffset = 0;
		this.yOffset = 0;

		this.charPosX = 0;
		this.charPosY = 0;
	}

	init = () => {
		GLR.init();
		GLR.showGrid = this.showGrid;
		console.log("Game has been initialised");

		MouseEvent.subscribeToClick(this);
		KeyEvent.subscribeToKeyDown(this);
		KeyEvent.subscribeToKeyUp(this);
		FSM.setState(FSM.states.play);
		FSM.logState();

		var width = 8;
		var height = 8;
		GLR.screenTileSize = 7;
		this.map.init(width, height);
		this.xOffset = -width / 2;
		this.yOffset = -height / 2;
		this.charPos = this.map.getTileID(this.charPosX, this.charPosY);
		this.oldCharPos = this.charPos;
	}

	onMove = (x, y) => {

	}

	onDrag = (dx, dy, x, y) => {

	}

	onClick = (x, y, down) => {
		if (down) {
			var index = GLR.getTileIndexAtMouse();

			if (index.x >= 0 && index.x < this.map.width) {
				if (index.y >= 0 && index.y < this.map.height) {
					this.charPosX = index.x;
					this.charPosY = index.y;
					this.xOffset = -this.map.width / 2 + index.x;
					this.yOffset = -this.map.height / 2 + index.y;
				}
			}
			
		}
	}

	onKeyUp = (e) => {
		switch (e.keyCode) {
			case 16: //Shift
				break;
			case 32: //space
				break;
			case 82: //R
				break;
			default:
				break;
		}

	}

	onKeyDown = (e) => {
		if (FSM.getStateID() === FSM.states.play) {
			switch (e.keyCode) {
				case 16: //shift
					break;
				case 32: //space
					break;
				case 87: //W
					this.yOffset--;
					this.charPosY--;
					break;
				case 65: //A
					this.xOffset--;
					this.charPosX--;
					break;
				case 83: //S
					this.yOffset++;
					this.charPosY++;
					break;
				case 68: //D
					this.xOffset++;
					this.charPosX++;
					break;
				case 27: //ESC    
					break;
				default:
					break;
			}
		}
	}

	update = () => {
		this.charPos = this.map.getTileID(this.charPosX, this.charPosY);
		this.map.tiles[this.oldCharPos] = 0;
		this.map.tiles[this.charPos] = 1;
		this.oldCharPos = this.charPos;

		GLR.update();
	}

	render = (dt) => {

		GLR.render(this.zoom, this.xOffset, this.yOffset);
	}
}

const GameInstance = new Game();

export default GameInstance;
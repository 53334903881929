import Shader from './Shader'
import { mat4 } from "gl-matrix";
import React from 'react';

class GLRenderer {

    constructor() {
        this.canvasRef = React.createRef();
        this.mousePos = [];
        this.mousePos.x = 0;
        this.mousePos.y = 0;
        this.renderables = [];
    }

    initGL = (webGL) => {
        this.gl = webGL;
        this.ext = this.gl.getExtension("ANGLE_instanced_arrays");
        this.shader = new Shader(webGL);
    }

    init = () => {
        const GL = this.gl;
        this.shader.useProgram();
        this.mvMatrix = mat4.create();
        this.pMatrix = mat4.create();

        GL.enable(GL.BLEND);
        GL.disable(GL.DEPTH_TEST);
        GL.blendFunc(GL.SRC_ALPHA, GL.ONE_MINUS_SRC_ALPHA);

        GL.viewport(0, 0, GL.drawingBufferWidth, GL.drawingBufferHeight);

        GL.uniformMatrix4fv(this.shader._pMatrix, false, this.pMatrix);
        GL.uniformMatrix4fv(this.shader._mvMatrix, false, this.mvMatrix);

        this.zoom = 10;
    }

    /* Project Specific */
    update = () => {

    }

    getCanvasX = () => {
        return this.mousePos.x - this.canvasRef.current.offsetLeft;
    }

    getCanvasY = () => {
        return this.mousePos.y - this.canvasRef.current.offsetTop;
    }

    render = (zoom, xOffset, yOffset) => {
        const GL = this.gl;
        this.zoom = zoom;
        this.xOffset = xOffset;
        this.yOffset = yOffset;

        const canvas = document.querySelector('canvas');
        if (canvas) {
            const canvasWidth = canvas.clientWidth;
            const canvasHeight = canvas.clientHeight;

            const shouldResize = canvas.width !== canvasWidth ||
                canvas.height !== canvasHeight;

            //This will ensure the canvas is always square
            if (shouldResize) {
                canvas.width = canvasWidth;
                canvas.height = canvasWidth;
            }

            GL.viewportWidth = canvas.width;
            GL.viewportHeight = canvas.height;
        }

        this.canvas = canvas;

        this.width = GL.drawingBufferWidth;
        this.height = GL.drawingBufferHeight;

        GL.viewport(0, 0, GL.drawingBufferWidth, GL.drawingBufferHeight);
        GL.clearColor(0.2, 0.2, 0.2, 1);
        GL.clear(this.gl.COLOR_BUFFER_BIT);
    }
}

const GLR = new GLRenderer();

export default GLR;

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
const Attributes = {
    POSITION: 'position',
    M_MATRIX: 'mMatrix',
    V_MATRIX: 'vMatrix',
    P_MATRIX: 'pMatrix',
    IP_MATRIX: 'ipMatrix',
}

export default Attributes;
import Attributes from './attributes.js';

const vertex = `

attribute vec2 ${Attributes.POSITION};

uniform mat4 mMatrix;
uniform mat4 vMatrix;
uniform mat4 pMatrix;
uniform mat4 ipMatrix;

varying vec4 positionOut;

void main(void) {
    
vec4 pos = pMatrix * vMatrix * mMatrix * vec4(${Attributes.POSITION}, 0, 1);
positionOut = vec4(${Attributes.POSITION}, 0, 1.0);
gl_Position = pos;

}

`
export default vertex;



import GLR from "./GL/GLRenderer";
import Renderable from "./GL/Renderable";

export default class Map extends Renderable {

    constructor() {
        super();
        this.numTiles = 0;
        this.tileSize = 1.0;

        this.vertices = []; //A single square, to be instanced across the tilemap.
        this.indices = [];  //A single square made of two triangles.
        this.offsets = [];  //The position of each square in the tilemap.

        this.tiles = new Array(this.numTiles);

        this.width = 0;
        this.height = 0;
    }

    /*
    Takes a square array and generates rendering data such as vertices and indices
    */
    init = (width, height) => {

        this.width = width;
        this.height = height;
        this.numTiles = width * height;

        delete this.cells;
        this.cells = new Array(this.numTiles);
 
        this.offsets = [

        ];

        this.vertices = [
            0, 0, //Bottom Left
            this.tileSize, 0, //Bottom Right
            this.tileSize, this.tileSize,  //Top Right
            0, this.tileSize  //Top Left
        ];

        this.uvs = [
            0, 0, //BL
            1, 0, //BR
            1, 1, //TR
            0, 1  //TL
        ];

        //Clockwise order
        this.indices = [0, 3, 2,  //Bottom left, Top Left, Top Right
            2, 1, 0]; //Top Right, Bottom Right, Bottom Left

        this.mapSize = Math.sqrt(this.numTiles);

        this.centerMap();
        this.fillMap(0);
        GLR.mapRenderer.initMap(this);
    };

    centerMap = () => {
        this.offsets = [];

        for (var y = 0; y < this.height; y++) {
            for (var x = 0; x < this.width; x++) {
                this.offsets.push((x * this.tileSize) - ((this.width * this.tileSize) / 2) );
                this.offsets.push((-y * this.tileSize) + ((this.height * this.tileSize) / 2) - this.tileSize);
            }
        }
    };

    /*Converts a 2D tile index to a 1D index*/
    getTileID = (x, y) => {
        return (y * this.width) + x;
    };

    fillMap = (id) => {
        for (var i = 0; i < this.height; i++) {
            for (var j = 0; j < this.width; j++) {
                this.tiles[(i * this.width) + j] = id;
            }
        }
    }
}
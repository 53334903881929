import React, { Component, createRef } from 'react';

import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import FastForwardRounded from '@mui/icons-material/FastForwardRounded';
import FastRewindRounded from '@mui/icons-material/FastRewindRounded';
import VolumeUpRounded from '@mui/icons-material/VolumeUpRounded';
import VolumeDownRounded from '@mui/icons-material/VolumeDownRounded';

import AudioResources from "./AudioResources";
import GameInstance from "./Game";

import {
  Box, Slider, IconButton, Stack, Paper
} from '@mui/material';

class AudioControls extends Component {
  constructor(props) {
    super(props)
    this.state = {
      trackIndex: parseInt(0),
      trackProgress: parseFloat(0),
      isPaused: true,
      isReady: false,
      volume: 0.2
    }

    this.intervalRef = createRef();

    this.first = true;
    //this.startTimer();
  }

  componentDidMount() {
    this.setState({
      trackIndex: parseInt(0),
      trackProgress: parseFloat(0),
      isPaused: true,
      isReady: false,
      volume: 0.2
    });
    GameInstance.setTrack(this.state.trackIndex);
    GameInstance.pauseMusic();
    GameInstance.audio.volume = this.state.volume;
    this.first = true;
  }
  componentWillUnmount() {

    clearInterval(this.intervalRef);
  }

  setTrackProgress = (progress) => {

    if (this.state.trackProgress >= GameInstance.audio.duration) {
      this.setState({ trackProgress: 0 });
    }
    else {
      this.setState({ trackProgress: progress });
    }
  }

  setTrackIndex = (index) => {
    this.setState({ trackIndex: index });
  }

  setVolume = (volume) => {
    this.setState({ volume: volume });
    GameInstance.audio.volume = volume;
  }

  setIsPaused = (paused) => {
    console.log(GameInstance);
    this.setState({ isPaused: paused });
    if (paused) {
      GameInstance.audio.pause();
    }
    else {
      if(this.first)
      {
        GameInstance.init();
        this.first = false;
      }
      GameInstance.audio.play();
      this.setTrackProgress(GameInstance.audio.currentTime);
    }
    GameInstance.audioContext.resume();
  }

  toPrevTrack = () => {
    if (this.state.trackIndex - 1 < 0) {
      this.setTrackIndex(AudioResources.length - 1);
      GameInstance.setTrack(AudioResources.length - 1);
    } else {
      this.setTrackIndex(this.state.trackIndex - 1);
      GameInstance.setTrack(this.state.trackIndex - 1);
    }

    GameInstance.audio.play();
    this.setIsPaused(false);
  };

  toNextTrack = () => {
    if (this.state.trackIndex < AudioResources.length - 1) {
      this.setTrackIndex(this.state.trackIndex + 1);
      GameInstance.setTrack(this.state.trackIndex + 1);
    } else {
      this.setTrackIndex(0);
      GameInstance.setTrack(0);
    }

    GameInstance.audio.play();
    this.setIsPaused(false);
  };

  startTimer = () => {
    // Clear any timers already running
    clearInterval(this.intervalRef.current);

    this.intervalRef.current = setInterval(() => {
      if (GameInstance.audio.ended) {
        this.toNextTrack();
      } else {
        this.setTrackProgress(GameInstance.audio.currentTime);
      }
    }, [250]);
  };


  onScrub = (value) => {
    clearInterval(this.intervalRef.current);

    if (value != NaN) {
      this.setTrackProgress(value);
      GameInstance.audio.currentTime = value;
    }

  };

  onScrubEnd = () => {
    if (this.state.isPaused) {
      this.setIsPaused(false);
    }
    this.startTimer();
  };

  valueLabelFormat = (value) => {
    return `${parseInt(value)}`;
  }

  render() {
    let darkMode = localStorage.getItem("preferred-theme") === "dark" ? true : false;
    const mainIconColor = darkMode ? '#fff' : '#000';
    const lightIconColor = darkMode ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)';

    var duration = !isNaN(GameInstance.audio.duration) ? GameInstance.audio.duration : 100;
    var progress = !isNaN(this.state.trackProgress) ? this.state.trackProgress : 0;
    
    return (
      <div className="music-controls">
        <Paper elevation={3} style={{ margin: "0px", padding: "10px" }}>
          <h5 style={{ margin: "0px", padding: "10px" }}>{this.state.trackIndex + 1} - {AudioResources[this.state.trackIndex].title}</h5>
          <i><h6 style={{ margin: "0px", padding: "0px" }}>{AudioResources[this.state.trackIndex].artist}</h6></i>
          <Slider
            aria-label="Progress"
            value={progress}
            defaultValue={1}
            min={0}
            step={1}
            max={duration}
            valueLabelDisplay="auto"
            valueLabelFormat={this.valueLabelFormat}
            onChange={(e) => this.onScrub(e.target.value)}
            onMouseUp={this.onScrubEnd}
            sx={{
              color: darkMode ? '#fff' : 'rgba(0,0,0,0.87)',
            }}

          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mt: -1,
            }}
          >
            <IconButton
              aria-label="Rewind 10s"
              onClick={() => { this.toPrevTrack() }}
              size="large">
              <FastRewindRounded fontSize="large" htmlColor={mainIconColor} />
            </IconButton>

            <IconButton
              aria-label={this.state.isPaused ? 'play' : 'pause'}
              onClick={() => { this.setIsPaused(!this.state.isPaused) }}
              size="large">
              {this.state.isPaused ? (
                <PlayArrowRounded
                  sx={{ fontSize: '3rem' }}
                  htmlColor={mainIconColor}
                />
              ) : (
                <PauseRounded sx={{ fontSize: '3rem' }} htmlColor={mainIconColor} />
              )}
            </IconButton>

            <IconButton aria-label="Skip 10s" onClick={() => { this.toNextTrack() }} size="large">
              <FastForwardRounded fontSize="large" htmlColor={mainIconColor} />
            </IconButton>
          </Box>

          <Stack spacing={2} direction="row" sx={{ mb: 1, px: 1 }} alignItems="center">
            <VolumeDownRounded htmlColor={lightIconColor} />
            <Slider
              aria-label="Volume"
              value={parseFloat(Number(this.state.volume))}
              defaultValue={1}
              min={0}
              step={0.01}
              max={1}
              onChange={(e) => this.setVolume(e.target.value)}
              sx={{
                color: darkMode ? '#fff' : 'rgba(0,0,0,0.87)',
              }}
            />
            <VolumeUpRounded htmlColor={lightIconColor} />
          </Stack>
        </Paper>
      </div>
    );
  }
}

export default AudioControls;


import Shader from './Shader'
import { mat4 } from "gl-matrix";
import React from 'react';

import MouseEvent from '../Input/MouseListener'

import MapRenderer from './RenderComponents/MapRenderer.js'

class GLRenderer {

    constructor() {
        this.canvasRef = React.createRef();
        this.mousePos = [];
        this.mousePos.x = 0;
        this.mousePos.y = 0;
        this.renderables = [];
        this.screenTileSize = 16;
    }

    initGL = (webGL) => {
        this.gl = webGL;
        this.ext = this.gl.getExtension("ANGLE_instanced_arrays");
        this.shader = new Shader(webGL);
    }

    init = () => {
        const GL = this.gl;
        this.shader.useProgram();
        this.mvMatrix = mat4.create();
        this.pMatrix = mat4.create();

        MouseEvent.subscribeToMove(this);
        MouseEvent.subscribeToClick(this);
        MouseEvent.subscribeToDrag(this);

        GL.enable(GL.BLEND);
        GL.disable(GL.DEPTH_TEST);
        GL.blendFunc(GL.SRC_ALPHA, GL.ONE_MINUS_SRC_ALPHA);

        GL.viewport(0, 0, GL.drawingBufferWidth, GL.drawingBufferHeight);

        GL.uniformMatrix4fv(this.shader._pMatrix, false, this.pMatrix);
        GL.uniformMatrix4fv(this.shader._mvMatrix, false, this.mvMatrix);

        const canvas = document.querySelector('canvas');
        this.canvas = canvas;

        this.mapRenderer = new MapRenderer();
        this.mapRenderer.gl = this.gl;
        this.mapRenderer.shader = this.shader;
        this.mapRenderer.ext = this.ext;
        this.mapRenderer.screenTileSize = this.screenTileSize;
    }

    getTileIndexAtMouse = () => {
        //The size of a tile in screen space coordinates (-1 to 1), 8 tiles wide would be 0.25,
        var screenSpaceOneX = (this.sc / this.width);
        var screenSpaceOneY = (this.sc / this.height);

        var xPos = (this.getCanvasX()) / this.zoom;
        var yPos = (this.getCanvasY()) / this.zoom;

        //The position clicked on the canvas in screen space coordinates (-1 to 1)
        var canvasXMinusOneToOne = 2.0 * (xPos / (this.width)) - 1.0;
        var canvasYMinusOneToOne = 2.0 * (yPos / (this.height)) - 1.0;

        // console.log(screenSpaceOneX)
        // console.log(canvasXMinusOneToOne)
        // console.log(canvasXMinusOneToOne/screenSpaceOneX)

        var iX =  1 + Math.floor(this.screenTileSize/2 + canvasXMinusOneToOne / screenSpaceOneX + ((this.zoom - 1) * (this.screenTileSize / (this.zoom))) + this.xOffset);
        var iY =  1 + Math.floor(this.screenTileSize/2 + canvasYMinusOneToOne / screenSpaceOneY + ((this.zoom - 1) * (this.screenTileSize / (this.zoom))) + this.yOffset);

        if (iX >= this.mapRenderer.map.width) { return this.mapRenderer.width - 1; }

        var index = [];
        index.x = iX;
        index.y = iY;
        return index;
    }


    onMove = (x, y) => {
        this.mousePos.x = x;
        this.mousePos.y = y;
    }

    onDrag = (dx, dy, x, y) => {

    }

    onClick = (x, y, down) => {
        GLR.mousePos.x = x;
        GLR.mousePos.y = y;

        if (down) {

            var index = this.getTileIndexAtMouse();
            this.mapRenderer.map.tiles[index.y * this.mapRenderer.map.width + index.x] = 1;
            console.log(index);

        }
    }

    update = () => {
        const GL = this.gl;
        if (this.canvas) {
            const canvasWidth = this.canvas.clientWidth;
            const canvasHeight = this.canvas.clientHeight;

            const shouldResize = this.canvas.width !== canvasWidth ||
                this.canvas.height !== canvasHeight;

            //This will ensure the canvas is always square
            if (shouldResize) {
                this.canvas.width = canvasWidth;
                this.canvas.height = canvasWidth;
            }

            GL.viewportWidth = this.canvas.width;
            GL.viewportHeight = this.canvas.height;
        }

        this.width = GL.drawingBufferWidth;
        this.height = GL.drawingBufferHeight;

        this.mapRenderer.zoom = this.zoom;
        this.mapRenderer.width = this.width;
        this.mapRenderer.height = this.height;
        this.mapRenderer.pMatrix = this.pMatrix;
        this.mapRenderer.mvMatrix = this.mvMatrix;
        this.mapRenderer.screenTileSize = this.screenTileSize;
    }

    getCanvasX = () => {
        return this.mousePos.x - this.canvas.offsetLeft;
    }

    getCanvasY = () => {
        return this.mousePos.y - this.canvas.offsetTop;
    }

    render = (zoom, xOffset, yOffset) => {
        const GL = this.gl;

        this.zoom = 1;
        this.xOffset = xOffset;
        this.yOffset = yOffset;

        GL.viewport(0, 0, this.width, this.height);
        GL.clearColor(0.2, 0.2, 0.2, 1);
        GL.clear(this.gl.COLOR_BUFFER_BIT);

        //Scale factor, this will scale the map to ensure the tiles fit on the screen.
        var sc = this.width / this.screenTileSize * 2;
        this.sc = sc;
        //Calculate what the border size will be so we can move half a border out to center the map
        var borderSize = (this.mapRenderer.map.tileSize / (this.width / this.screenTileSize));

        mat4.ortho(this.pMatrix, -this.width / sc, this.width / sc, -this.height / sc, this.height / sc, 0.1, 100.0);
        mat4.identity(this.mvMatrix);
        mat4.scale(this.mvMatrix, this.mvMatrix, [this.zoom, this.zoom, this.zoom]);



        if (Math.log2(this.screenTileSize) % 1 !== 0) {
            mat4.translate(this.mvMatrix, this.mvMatrix, [-xOffset + (borderSize / 2) - 1, yOffset + (borderSize / 2), -1]);
            mat4.translate(this.mvMatrix, this.mvMatrix, [this.mapRenderer.map.tileSize / 2, this.mapRenderer.map.tileSize / 2, 0]);
        }
        else {
            mat4.translate(this.mvMatrix, this.mvMatrix, [-xOffset + (borderSize / 2), yOffset + (borderSize / 2), -1]);
        }

        GL.uniform1f(this.shader._zoom, zoom);
        GL.uniform1f(this.shader._canvasSize, this.width);

        this.mapRenderer.render();
    }

}

const GLR = new GLRenderer();

export default GLR;
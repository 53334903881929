import Shader from './Shader'
import { mat4 } from "gl-matrix";
import React from 'react';

class GLRenderer {

    constructor() {
        this.canvasRef = React.createRef();
        this.mousePos = [];
        this.mousePos.x = 0;
        this.mousePos.y = 0;
        this.renderables = [];
    }

    initGL = (webGL) => {
        this.gl = webGL;
        this.ext = this.gl.getExtension("ANGLE_instanced_arrays");
        this.shader = new Shader(webGL);
    }

    init = (barCount, space) => {
        const GL = this.gl;
        this.shader.useProgram();
        this.vMatrix = mat4.create();
        this.pMatrix = mat4.create();

        GL.enable(GL.BLEND);
        GL.disable(GL.DEPTH_TEST);
        GL.blendFunc(GL.SRC_ALPHA, GL.ONE_MINUS_SRC_ALPHA);

        GL.viewport(0, 0, GL.drawingBufferWidth, GL.drawingBufferHeight);

        this.pWidth = 1024;
  
        this.barCount = barCount;
        this.barWidth = this.pWidth / (barCount+(barCount*space));
        this.barSpace = this.barWidth * space;

        this.vertices = [
            -this.barWidth / 2, 0,
            this.barWidth / 2, 0,
            -this.barWidth / 2, 0,
            this.barWidth / 2, 0,
        ];

        this.flip = false;

        this.vertexBuffer = GL.createBuffer();
        GL.bindBuffer(GL.ARRAY_BUFFER, this.vertexBuffer);
        GL.bufferData(GL.ARRAY_BUFFER, new Float32Array(this.vertices), GL.STATIC_DRAW);

        GL.uniformMatrix4fv(this.shader._pMatrix, false, this.pMatrix);
        GL.uniformMatrix4fv(this.shader._vMatrix, false, this.vMatrix);
    }

    updateVisualization = (i) => {
        this.vertices[1] = (this.frequencyData[i]) + 1;
        this.vertices[3] = (this.frequencyData[i]) + 1;
    }

    /* Project Specific */
    update = (frequencyData) => {
        this.frequencyData = frequencyData;
    }

    getCanvasX = () => {
        return this.mousePos.x - this.canvasRef.current.offsetLeft;
    }

    getCanvasY = () => {
        return this.mousePos.y - this.canvasRef.current.offsetTop;
    }

    drawBar = (flip) => {
        const GL = this.gl;

        for (var i = 0; i < this.barCount; i++) {
            this.updateVisualization(i);

            GL.bindBuffer(GL.ARRAY_BUFFER, this.vertexBuffer);
            GL.bufferData(GL.ARRAY_BUFFER, new Float32Array(this.vertices), GL.STATIC_DRAW);

            var mMatrix = mat4.create();
            mat4.identity(mMatrix);

            if (flip)
                mat4.scale(mMatrix, mMatrix, [1, -1, 1]);

            mat4.translate(mMatrix, mMatrix, [-this.pWidth / 2//SHIFT LEFT HALF A SCREEN
                + (i * this.barWidth)  //Move right from left of screen by width * index
                + (i * this.barSpace) //Add Space
                + (this.barWidth - this.barSpace), //Position is middle of bar so move in by a bar to get half a bar padding
                  0.0 //Place at middle of screen
                , 0.0]);

            GL.uniformMatrix4fv(this.shader._mMatrix, false, mMatrix);

            GL.drawArrays(GL.TRIANGLE_STRIP, 0, 4);

        }
    }

    render = (zoom, xOffset, yOffset) => {
        const GL = this.gl;
        this.zoom = zoom;
        this.xOffset = xOffset;
        this.yOffset = yOffset;

        const canvas = document.querySelector('canvas');
        if (canvas) {
            const canvasWidth = canvas.clientWidth;
            const canvasHeight = canvas.clientHeight;

            const shouldResize = canvas.width !== canvasWidth ||
                canvas.height !== canvasHeight;

            //This will ensure the canvas is always square
            if (shouldResize) {
                canvas.width = canvasWidth;
                canvas.height = canvasWidth;
            }

            GL.viewportWidth = canvas.width;
            GL.viewportHeight = canvas.height;
        }

        this.canvas = canvas;

        this.width = GL.drawingBufferWidth;
        this.height = GL.drawingBufferHeight;

        GL.viewport(0, 0, GL.drawingBufferWidth, GL.drawingBufferHeight);
        GL.clearColor(0.2, 0.2, 0.2, 1);
        GL.clear(this.gl.COLOR_BUFFER_BIT);

        mat4.ortho(this.pMatrix, -this.pWidth / 2.0, this.pWidth / 2.0, -512, 512, 0.01, 1000);

        mat4.identity(this.vMatrix);
        mat4.translate(this.vMatrix, this.vMatrix, [0.0, 0.0, -6.0]);

        GL.vertexAttribPointer(this._vPosition, 2, GL.FLOAT, false, 0, 0);
        GL.enableVertexAttribArray(this._vPosition);

        GL.uniformMatrix4fv(this.shader._pMatrix, false, this.pMatrix);
        GL.uniformMatrix4fv(this.shader._vMatrix, false, this.vMatrix);

        this.drawBar(true);
        this.drawBar(false);

    }
}

const GLR = new GLRenderer();

export default GLR;
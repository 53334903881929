import Shader from '../Shader'
import { mat4 } from "gl-matrix";

export default class MapRenderer {

    constructor() {
        this.canvas = null;

        this.map = null;
        this.showGrid = true;
    }



    renderLayer = (layer) => {
        const GL = this.gl;
        GL.bindBuffer(GL.ARRAY_BUFFER, this.map.tileBuffer);
        GL.bufferData(GL.ARRAY_BUFFER, new Float32Array(this.map.tiles), GL.STATIC_DRAW);

        GL.vertexAttribPointer(this.shader._index, 1, GL.FLOAT, false, 0, 0);
        this.ext.vertexAttribDivisorANGLE(this.shader._index, 1);

        GL.bindBuffer(GL.ELEMENT_ARRAY_BUFFER, this.map.indexBuffer);
        this.ext.drawElementsInstancedANGLE(GL.TRIANGLES, 6, GL.UNSIGNED_SHORT, 0, this.map.numTiles);
        GL.flush();
    }

    render = () => {
        const GL = this.gl;

        if (this.map) {
            GL.bindBuffer(GL.ARRAY_BUFFER, this.map.vertexBuffer);
            GL.vertexAttribPointer(this.shader._position, 2, GL.FLOAT, false, 0, 0);

            GL.bindBuffer(GL.ARRAY_BUFFER, this.map.uvBuffer);
            GL.vertexAttribPointer(this.shader._uv, 2, GL.FLOAT, false, 0, 0);

            GL.bindBuffer(GL.ARRAY_BUFFER, this.map.offsetBuffer);
            GL.vertexAttribPointer(this.shader._offset, 2, GL.FLOAT, false, 0, 0);
            this.ext.vertexAttribDivisorANGLE(this.shader._offset, 1);

            GL.uniformMatrix4fv(this.shader._pMatrix, false, this.pMatrix);
            GL.uniformMatrix4fv(this.shader._mvMatrix, false, this.mvMatrix);

            if (this.showGrid === true) {
                GL.uniform1f(this.shader._tileSize, this.map.tileSize);
            }
            else {
                GL.uniform1f(this.shader._tileSize, 100);
            }

            GL.uniform1f(this.shader._mapSize, this.screenTileSize);
    

            this.renderLayer();
        }
    }


    initMap = (map) => {
        const GL = this.gl;
        this.map = map;

        this.map.vertexBuffer = GL.createBuffer();
        GL.bindBuffer(GL.ARRAY_BUFFER, this.map.vertexBuffer);
        GL.bufferData(GL.ARRAY_BUFFER, new Float32Array(this.map.vertices), GL.STATIC_DRAW);
        GL.bindBuffer(GL.ARRAY_BUFFER, null);

        this.map.indexBuffer = GL.createBuffer();
        GL.bindBuffer(GL.ELEMENT_ARRAY_BUFFER, this.map.indexBuffer);
        GL.bufferData(GL.ELEMENT_ARRAY_BUFFER, new Uint16Array(this.map.indices), GL.STATIC_DRAW);
        GL.bindBuffer(GL.ELEMENT_ARRAY_BUFFER, null);

        this.map.uvBuffer = GL.createBuffer();
        GL.bindBuffer(GL.ARRAY_BUFFER, this.map.uvBuffer);
        GL.bufferData(GL.ARRAY_BUFFER, new Float32Array(this.map.uvs), GL.STATIC_DRAW);
        GL.bindBuffer(GL.ARRAY_BUFFER, null);

        this.map.offsetBuffer = GL.createBuffer();
        GL.bindBuffer(GL.ARRAY_BUFFER, this.map.offsetBuffer);
        GL.bufferData(GL.ARRAY_BUFFER, new Float32Array(this.map.offsets), GL.STATIC_DRAW);
        GL.bindBuffer(GL.ARRAY_BUFFER, null);

        this.map.tileBuffer = GL.createBuffer();
        GL.bindBuffer(GL.ARRAY_BUFFER, this.map.tileBuffer);
        GL.bufferData(GL.ARRAY_BUFFER, new Float32Array(this.map.cells), GL.STATIC_DRAW);
        GL.bindBuffer(GL.ARRAY_BUFFER, null);

    }
}


import { red } from "@mui/material/colors"
import { createTheme, adaptV4Theme } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';

const baseTheme = createTheme(adaptV4Theme({
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
    fontFamilySecondary: "'Roboto Condensed', sans-serif"
  }
}))

const darkTheme = createTheme(adaptV4Theme({
  ...baseTheme,
  palette: {
    mode: "dark",
    primary: red,
    background: {
      default: "#2a2a2a"
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#FFFFFF",
      default: "#FFFFFF",
      main: "#FFFFFF"
    }
  }
}))
const lightTheme = createTheme(adaptV4Theme({
  ...baseTheme,
  palette: {
    mode: "light",
    primary: red,
    background: {
      default: "#CFD8DC"
      // default: "#CFD8DC"
    },
    text: {
      primary: "#222222",
      secondary: "#222222",
    }

  }
}))

export { darkTheme, lightTheme }
import VertexSource from '../resources/shaders/colour/vertex'
import FragmentSource from '../resources/shaders/colour/fragment'

import Attributes from '../resources/shaders/colour/attributes'

export default class Shader {
    constructor(gl) {
        const GL = gl;
        this.gl = GL;

        const vertexShader = this.getShader(VertexSource, GL.VERTEX_SHADER, "VERTEX");
        const fragmentShader = this.getShader(FragmentSource, GL.FRAGMENT_SHADER, "FRAGMENT");

        const program = GL.createProgram();
        this.program = program;

        GL.attachShader(this.program, vertexShader);
        GL.attachShader(this.program, fragmentShader);
    
        GL.linkProgram(this.program);

        this._position = GL.getAttribLocation(this.program, Attributes.POSITION);

        this._mMatrix = GL.getUniformLocation(this.program, Attributes.M_MATRIX);
        this._vMatrix = GL.getUniformLocation(this.program, Attributes.V_MATRIX);
        this._pMatrix = GL.getUniformLocation(this.program, Attributes.P_MATRIX);
 
        GL.enableVertexAttribArray(this._position);
    }

    getShader = (source, type, typeString) => {
        const GL = this.gl;

        var shader = GL.createShader(type);
        GL.shaderSource(shader, source);
        GL.compileShader(shader);
        if (!GL.getShaderParameter(shader, GL.COMPILE_STATUS)) {
            alert("ERROR IN " + typeString + " SHADER : " + GL.getShaderInfoLog(shader));
            return false;
        }
        return shader;
    };

    useProgram = () => {
        this.gl.useProgram(this.program);
    }

    enablePositionAttributes = () => {
        const GL = this.gl;
        GL.enableVertexAttribArray(this._position);
    }
}









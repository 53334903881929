import Snake from './images/snake.png';
import Audio from './images/audio.png';
import Tiles from './images/tiles.png';
import Cellular from './images/cellular_automata.png';

export default [
    {
        title: "Snake",
        description: "The classic snake game",
        imgSrc: Snake,
        navLink: "/snake",
        tags: ["Javascript", "WebGL", "Game"],
        priority: 3,
    },
    {
        title: "Tile Editor",
        description: "A 2D tile editor for making maps for games.",
        imgSrc: Tiles,
        navLink: "/tile_editor",
        tags: ["Javascript", "WebGL", "Software"],
        priority: 2,
    },
    {
        title: "Cellular Automata",
        description: "Conways game of life, a 2D cellular automata which follows simple rules to create interesting patterns.",
        imgSrc: Cellular,
        navLink: "/cellular_automata",
        tags: ["Javascript", "WebGL", "Game"],
        priority: 1,
    },
    {
        title: "Audio Visualiser",
        description: "An audio visualiser which takes audio played from the tab and shows it as bars.",
        imgSrc: Audio,
        navLink: "/audio_visualiser",
        tags: ["Javascript", "WebGL", "Software"],
        priority: 2
    },
];

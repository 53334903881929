import Daytime from './resources/audio/daytime_intro.mp3'
import Busy from './resources/audio/busy_intro.mp3'
import Evening from './resources/audio/evening_intro.mp3'
import Working from './resources/audio/working_intro.mp3'
import Talk from './resources/audio/talk_intro.mp3'
import Morning from './resources/audio/morning_intro.mp3'
import Romance from './resources/audio/romance_intro.mp3'
import Farm from './resources/audio/farmhouse_intro.mp3'
import Night from './resources/audio/night_intro.mp3'
import Title from './resources/audio/title.mp3'
// All of these artists are at https://pixabay.com/music/search/mood/laid%20back/
export default [
  {
    title: "Title Theme",
    artist: "Humble Bundle Cosy Farm Music Pack",
    audioSrc: Title,
  },
  {
    title: "Daytime",
    artist: "Humble Bundle Cosy Farm Music Pack",
    audioSrc: Daytime,
  },
  {
    title: "Busy",
    artist: "Humble Bundle Cosy Farm Music Pack",
    audioSrc: Busy,
  },
  {
    title: "Evening",
    artist: "Humble Bundle Cosy Farm Music Pack",
    audioSrc: Evening,
  },
  {
    title: "Working",
    artist: "Humble Bundle Cosy Farm Music Pack",
    audioSrc: Working,
  },
  {
    title: "Talk",
    artist: "Humble Bundle Cosy Farm Music Pack",
    audioSrc: Talk,
  },
  {
    title: "Morning",
    artist: "Humble Bundle Cosy Farm Music Pack",
    audioSrc: Morning,
  },
  {
    title: "Romance",
    artist: "Humble Bundle Cosy Farm Music Pack",
    audioSrc: Romance,
  },
  {
    title: "Farm",
    artist: "Humble Bundle Cosy Farm Music Pack",
    audioSrc: Farm,
  },
  {
    title: "Night",
    artist: "Humble Bundle Cosy Farm Music Pack",
    audioSrc: Night,
  },
];

import React, { Component } from 'react';
import GLR from './GL/GLRenderer';
import GameInstance from './Game';
import FSM from './StateMachine'

import MouseEvent from './Input/MouseListener'
import KeyEvent from './Input/KeyListener'

//import Grid from '@mui/material/Grid';
import NormalContainer from '@mui/material/Container';
import { withStyles } from '@mui/styles';

import {
    Divider, Grid, Box, Accordion, AccordionSummary, AccordionDetails, Typography, Slider, FormControlLabel,
    Switch, MenuItem, Button, ButtonGroup, FormControl, InputLabel, Select, IconButton, TextField, Stack
} from '@mui/material';
import NormalPaper from '@mui/material/Paper';
import { Scrollbars } from 'react-custom-scrollbars-2';

import AudioControls from './AudioControls';

const Container = withStyles({
    root: {
        height: "89vh",
        minHeight: 500,
        fontSize: 30,
        width: "auto"
    },
    media: {
        padding: '0px',
    }
})(NormalContainer);

const Paper = withStyles({
    root: {
        height: '100%',
        width: '100%',
        padding: '0px',
        margin: "0px",

    },
})(NormalPaper);

//Ensure that requestAnimationFrame is called at 60FPS
window.requestAnimFrame = (function () {
    return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        function (callback) {
            window.setTimeout(callback, 10000 / 60);
        };
})();

export default class Main extends Component {

    constructor(props) {
        super(props)
        this.lastTime = 0;
        this.state = {
            numBars: 128,
            dBRange: [-100, -30],
            smooth: 0.85
        }

        this.currentTimeInterval = null;
    }

    autoUpdate = () => {
        GameInstance.update();

        this.updateLoop = setTimeout(this.autoUpdate, this.state.tickRate);
    };

    init() {
        document.body.addEventListener("wheel", e => {
            if (e.ctrlKey)
                e.preventDefault();
        });

        MouseEvent.init();
        KeyEvent.init();
        GameInstance.init();
        this.autoUpdate();
    }

    shouldComponentUpdate(newState) {
        return true;
    }

    update = () => {
        var now = Date.now();
        var dt = (now - this.lastTime) / 1000.0;
        this.lastTime = now;

        GameInstance.render(dt);

        this.loop = window.requestAnimationFrame(this.update);
    }


    componentDidMount() {
        const canvas = document.querySelector('canvas');
        this.canvas = canvas;

        if (!canvas) {
            return;
        }

        const webGL = canvas.getContext("experimental-webgl", {
            antialias: false
        });

        canvas.style.width = "100%";
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;

        webGL.viewportWidth = canvas.width;
        webGL.viewportHeight = canvas.height;

        //Initialise the GL Renderer
        GLR.initGL(webGL);

        //Initialise the game state
        this.init();

        //Start the update/render loop
        this.update();

        this.setState({ ready: true });


    }

    componentWillUnmount() {

        window.cancelAnimationFrame(this.loop);
        window.clearTimeout(this.updateLoop);
        clearInterval(this.currentTimeInterval);
        GameInstance.audio.pause();
        FSM.setState(FSM.states.paused);
    }



    render() {
        const classes = this.props;

        const handleSlider = (event) => {
            this.setState({ [event.target.name]: event.target.value });

            switch (event.target.name) {
                case "numBars":
                    GameInstance.barCount = event.target.value;
                    GameInstance.reload();
                    break;
                case "smooth":
                    GameInstance.smooth = event.target.value;
                    GameInstance.reload();
                    break;
            }
        };


        const minDistance = 10;

        const handleMinMax = (event, newValue, activeThumb) => {

            if (!Array.isArray(newValue)) {
                return;
            }

            if (activeThumb === 0) {
                this.setState({ [event.target.name]: [Math.min(newValue[0], this.state.dBRange[1] - minDistance), this.state.dBRange[1]] });
            } else {
                this.setState({ [event.target.name]: [this.state.dBRange[0], Math.max(newValue[1], this.state.dBRange[0] + minDistance)] });
            }

            GameInstance.minDecibels = this.state.dBRange[0];
            GameInstance.maxDecibels = this.state.dBRange[1];
            GameInstance.reload();
        };

        const barNums = [
            {
                value: 16,
                label: '16',
            },
            {
                value: 32,
                label: '32',
            },
            {
                value: 64,
                label: '64',
            },
            {
                value: 128,
                label: '128',
            },
            {
                value: 256,
                label: '256',
            }
        ];
        let darkMode = localStorage.getItem("preferred-theme") === "dark" ? true : false;
        const mainIconColor = darkMode ? '#fff' : '#000';
        const lightIconColor = darkMode ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)';
        return (
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={0} style={{ padding: "0px", margin: "0px" }}>
                <Grid item xs={3}>
                    <Container style={{ paddingRight: "0px" }} >
                        <Paper ref={this.tileSetRef} style={{ paddingRight: "0px" }}  > Information
                            <Container style={{ height: "96%", minHeight: "1vh", fontSize: "16px", padding: "10px" }}>
                                <Scrollbars autoHide={true} style={{ width: "100%", height: "98%"}}>

                                    <br />
                                    <Typography gutterBottom align="left">This project uses the AnalyserNode and Audio features of the Web Audio API. By using a FFT (Fast Fourier Transform) it is possible to extract the frequency data from an audio wave into bins which can then be rendered at relative scales to produce an audio visualisation. In this case, this has been done using WebGL, while the UI elements are React and Material-UI. </Typography>
                                    <Divider />
                                    <Typography paddingTop="10px" align="left" gutterBottom>Controls:</Typography>
                                    <Typography align="left" width="99%" >Music Control: The current track can be changed with the forward and backward buttons, while the pause/play button will start and stop the music.</Typography>
                                    <br/>
                                    <Typography align="left" width="99%" >Number of Bars: This will effect the number of FFT frequency bins and will therefore show more or less detail.</Typography>
                                    <br/>
                                    <Typography align="left" width="99%" >Min/Max dB: This will change the minimum required volume to start showing a bar, and the maximum will scale this value.</Typography>
                                    <br/>
                                    <Typography align="left" width="99%" gutterBottom >Smoothing: The smoothing value will scale the speed at which the bars move, therefore taking longer to animate.</Typography>
                                
                                </Scrollbars>
                            </Container>
                        </Paper>
                    </Container>
                </Grid>

                <Grid item xs={6} padding="0px" margin="0px">
                    <Container>
                        <Box
                            display="flex"
                            justifyContent="center"
                            justify="center"
                            alignItems="center"
                            minHeight="89vh"
                        >
                            <canvas ref={GLR.canvasRef} align="center" justify="center" id="canvas" style={{ border: '1px solid black' }}>
                                <code>&lt;canvas&gt;</code> element.
                                Your browser doesn't appear to support the
                            </canvas>

                        </Box>
                    </Container>
                </Grid>

                <Grid item xs={3} padding="0px" margin="0px">
                    <Container style={{ paddingLeft: "0px" }} >
                        <Paper ref={this.functionRef}>
                            <Scrollbars autoHide={true} style={{ width: "100%", height: "98%", overflowX: "hidden" }}>
                                <Container>
                                    Music Controls <br /><Divider /><br />
                                    {this.state.ready ? <AudioControls /> : "Not Ready"}
                                    <br /><Divider /><br />
                                    <Typography align="left" gutterBottom>Number of Bars</Typography>
                                    <Slider width="100%" value={this.state.numBars} step={null} marks={barNums} min={1} max={256} valueLabelDisplay="auto" onChange={handleSlider} name="numBars" sx={{ color: darkMode ? '#fff' : 'rgba(0,0,0,0.87)', }} />

                                    <Typography align="left" gutterBottom>Min/Max dB</Typography>
                                    <Slider width="100%" value={this.state.dBRange} step={1} min={-100} max={0} valueLabelDisplay="auto" onChange={handleMinMax} name="dBRange" disableSwap sx={{ color: darkMode ? '#fff' : 'rgba(0,0,0,0.87)', }} />

                                    <Typography align="left" gutterBottom>Smoothing</Typography>
                                    <Slider width="100%" value={this.state.smooth} step={0.01} min={0.6} max={0.9} valueLabelDisplay="auto" onChange={handleSlider} name="smooth" disableSwap sx={{ color: darkMode ? '#fff' : 'rgba(0,0,0,0.87)', }} />

                                </Container>



                            </Scrollbars>
                        </Paper>
                    </Container>
                </Grid>
            </Grid>
        );
    }
}
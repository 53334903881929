import React, { Component } from 'react';
import GLR from './GL/GLRenderer';
import GameInstance from './Game';
import FSM from './StateMachine'

import MouseEvent from './Input/MouseListener'
import KeyEvent from './Input/KeyListener'

//import Grid from '@mui/material/Grid';
import NormalContainer from '@mui/material/Container';
import { withStyles } from '@mui/styles';

import {
    Divider, Grid, Box, Accordion, AccordionSummary, AccordionDetails, Typography, Slider, FormControlLabel,
    Switch, MenuItem, Button, ButtonGroup, FormControl, InputLabel, Select, IconButton, TextField
} from '@mui/material';
import NormalPaper from '@mui/material/Paper';
import { Scrollbars } from 'react-custom-scrollbars-2';

const Container = withStyles({
    root: {
        height: "89vh",
        minHeight: 500,
        fontSize: 30,
        width: "auto"
    },
    media: {
        padding: '0px',
    }
})(NormalContainer);

const Paper = withStyles({
    root: {
        height: '100%',
        width: '100%',
        padding: '0px',
        margin: "0px",

    },
})(NormalPaper);

//Ensure that requestAnimationFrame is called at 60FPS
window.requestAnimFrame = (function () {
    return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        function (callback) {
            window.setTimeout(callback, 1000 / 60);
        };
})();

export default class Main extends Component {

    constructor(props) {
        super(props)
        this.lastTime = 0;

        this.saveLoadRef = React.createRef();

        this.state = {
            deltaTime: 0
        }
    }

    autoUpdate = () => {
        //GameInstance.update();

        //this.updateLoop = setTimeout(this.autoUpdate, this.state.tickRate);
    };

    init() {
        document.body.addEventListener("wheel", e => {
            if (e.ctrlKey)
                e.preventDefault();
        });

        MouseEvent.init();
        KeyEvent.init();
        GameInstance.init();
        //this.autoUpdate();
    }

    shouldComponentUpdate(newState) {
        return true;
    }

     update = async () => {
        var now = performance.now()
        var dt = (now - this.lastTime) / 1000.0;
        this.lastTime = now;

        GameInstance.update();
        GameInstance.render(dt);
        GameInstance.delta = dt;

        this.setState({ deltaTime: dt });
        

        this.loop = window.requestAnimationFrame(this.update);
    }

    componentDidMount() {
        const canvas = document.querySelector('canvas');
        this.canvas = canvas;

        if (!canvas) {
            return;
        }

        const webGL = canvas.getContext("experimental-webgl", {
            antialias: false
        });

        canvas.style.width = "100%";
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;

        webGL.viewportWidth = canvas.width;
        webGL.viewportHeight = canvas.height;

        //Initialise the GL Renderer
        GLR.initGL(webGL);

        //Initialise the game state
        this.init();

        //Start the update/render loop
        this.update();
    }

    componentWillUnmount() {

        window.cancelAnimationFrame(this.loop);
        //window.clearTimeout(this.updateLoop);
    }

    render() {
        const classes = this.props;

        return (
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={0} style={{ padding: "0px", margin: "0px" }}>

                <Grid item xs={3}>
                    <Container style={{ paddingRight: "0px" }} >
                        <Paper ref={this.tileSetRef} style={{ paddingRight: "0px" }}  >
                            <Container style={{ paddingRight: "0px", height: "96%", minHeight: "1vh", fontSize: "16px" }}>
                                <Scrollbars autoHide={true} style={{ width: "100%", height: "98%" }}>

                                </Scrollbars>
                            </Container>
                        </Paper >
                    </Container >
                </Grid >

                <Grid item xs={6} padding="0px" margin="0px">
                    <Container>
                        <Box
                            display="flex"
                            justifyContent="center"
                            justify="center"
                            alignItems="center"
                            minHeight="89vh"
                        >
                            <canvas ref={GLR.canvasRef} align="center" justify="center" id="canvas" style={{ border: '1px solid black' }}>
                                <code>&lt;canvas&gt;</code> element.
                                Your browser doesn't appear to support the
                            </canvas>

                        </Box>
                    </Container>
                </Grid>

                <Grid item xs={3} padding="0px" margin="0px">
                    <Container style={{ paddingLeft: "0px" }} >
                        <Paper ref={this.functionRef}>
                            <Scrollbars autoHide={true} style={{ width: "100%", height: "98%", overflowX: "hidden" }}>
                            <Typography>Frametime: {Math.round(this.state.deltaTime * 1000)}ms </Typography>
                            <Typography>FPS: {Math.round(1000/(1000*(this.state.deltaTime)))}</Typography>
                            </Scrollbars>
                        </Paper>
                    </Container>
                </Grid>
            </Grid >
        );
    }
}
import MouseEvent from './Input/MouseListener'
import KeyEvent from './Input/KeyListener'
import FSM from './StateMachine'

import GLR from './GL/GLRenderer';

import AudioResources from './AudioResources'

class Game {
	constructor() {
		FSM.setState(FSM.states.paused);
		this.audio = new Audio();
		this.audio.src = AudioResources[0].audioSrc;
		this.audio.id = "audioData";
		this.audio.controls = true;
		this.audio.autoplay = false;
		this.audio.loop = true;
		
		this.audio.volume = 0.2;
		this.trackIndex = 0;
		
	}

	setTrack = (track) =>
	{
		this.trackIndex = track;
		this.audio.src = AudioResources[track].audioSrc;
		this.audioContext.resume();
	}

	init = () => {
		this.audioContext = new AudioContext();
		this.audioContext.resume();

		this.audio = new Audio();
		this.audio.src = AudioResources[this.trackIndex].audioSrc;
		this.audio.id = "audioData";
		this.audio.controls = true;
		this.audio.autoplay = false;
		this.audio.loop = true;

		this.audioSrc = this.audioContext.createMediaElementSource(this.audio);
		this.analyser = this.audioContext.createAnalyser();

		this.audioSrc.connect(this.analyser);
		this.analyser.connect(this.audioContext.destination);

		this.space = 0.5;
		this.smooth = 0.85;
		this.barCount = 256;

		this.analyser.smoothingTimeConstant = this.smooth;
		this.analyser.fftSize = this.barCount;
		this.frequencyData = new Uint8Array(this.analyser.frequencyBinCount);
		this.barCount = this.analyser.frequencyBinCount;
		this.averageVolume = 0.5;

		this.minDecibels = this.analyser.minDecibels;
		this.maxDecibels = this.analyser.maxDecibels;
		GLR.init(this.barCount, this.space);
	}

	playMusic = () => {
		FSM.setState(FSM.states.play);
		this.audioContext.resume();
		this.audio.play();
	}

	stopMusic = () => {
		FSM.setState(FSM.states.stopped);
		this.audio.pause();
		this.audio.currentTime = 0;
	}

	pauseMusic = () => {
		FSM.setState(FSM.states.paused);
		this.audio.pause();
	}

	getAverageVolume = () => {
		var total = 0;

		var length = this.frequencyData.length;

		for (var i = 0; i < length; i++) {
			total += this.frequencyData[i];
		}

		this.averageVolume = total / length;

	}

	reload = () => {
		this.analyser.fftSize = this.barCount * 2;
		this.analyser.minDecibels = this.minDecibels;
		this.analyser.maxDecibels = this.maxDecibels;
		this.frequencyData = new Uint8Array(this.analyser.frequencyBinCount);

		this.analyser.smoothingTimeConstant = this.smooth;

		if(this.currentTime > 0){
			this.audio.currentTime = this.currentTime;
		}else{
			this.currentTime = 0;
		}
			
		GLR.init(this.barCount, this.space);
	};

	update = () => {
		this.analyser.getByteFrequencyData(this.frequencyData);
		this.getAverageVolume(this.frequencyData);
		this.currentTime = this.audio.currentTime;

		GLR.update(this.frequencyData);
	}

	render = (dt) => {

		GLR.render();
	}
}

const GameInstance = new Game();

export default GameInstance;
import React, { Component } from 'react';
import { withStyles } from '@mui/styles';


//import Incremental from './images/incremental.png';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { NavLink } from 'react-router-dom';
import ProjectList from './ProjectList';
import ProjectSearch from './ProjectSearch'

const Project = withStyles({
    root: {
        backgroundColor: "#222222",
        height: "100%",
        width: "100%",
        padding: "1px"
    },
})(CardActionArea);

const ProjectContent = withStyles({
    root: {
        minHeight: "120px",
        backgroundColor: "#444444",
        height: "100",
        width: "100%",
        overflow: "hidden",

    },
})(CardContent);

const ProjectCard = (props) => {

    return (
        <Card>
            <NavLink to={props.navLink} >
                <Project >
                    <CardMedia
                        src={props.imgSrc}
                        component="img"
                        title={props.title}
                        draggable={false} />
                    <ProjectContent>
                        <Typography color="white" gutterBottom variant="h5" component="h2">
                            {props.title}
                        </Typography>
                        <Typography variant="body2" color="white" component="p">
                            {props.description}
                        </Typography>
                    </ProjectContent>
                </Project>
            </NavLink>
        </Card>
    );
};



export default class Projects extends Component {
    constructor(props) {
        super(props);

        this.SortedProjectList = ProjectList; 
        this.SortedProjectList = this.SortedProjectList.sort((a, b) => a.priority > b.priority ? 1 : -1);

        this.searchValue = [];
        this.searchInputValue = "";
    };

    sortProjects = () => {
        this.SortedProjectList = ProjectList.filter((project) => {
            let textExists = project.title.toLowerCase().includes(this.searchInputValue.toLowerCase()) || project.description.toLowerCase().includes(this.searchInputValue.toLowerCase());
            let tagsExist = true;

            this.searchValue.forEach(tag => {
                let foundTags = project.tags.includes(tag.value);
                if(foundTags == false)
                {
                    tagsExist = false;
                }
            });           
            
            return textExists && tagsExist;

        });
        this.SortedProjectList = this.SortedProjectList.sort((a, b) => a.priority > b.priority ? 1 : -1);
        this.forceUpdate();

    };

    onChangeSearchValue = (newVal) => {
        this.searchValue = newVal;
        this.sortProjects();
    };

    onChangeSearchInputValue = (newVal) => {
        this.searchInputValue = newVal;
        this.sortProjects();
    };  

    render() {
        const classes = this.props;

        return (

            <section id="projects" style={{ minHeight: '2000px' }}>
            For C++ projects please visit my github: <a href="https://github.com/Vangoule/" style={{color:"red"}}>here</a> - these will soon be featured as projects on this website.
                <h2>Web Projects</h2>
                <ProjectSearch onChangeValue={ (newVal) => {this.onChangeSearchValue(newVal)} } onChangeinput={ (newInput) => {this.onChangeSearchInputValue(newInput)} } /><br />
                <div className={classes.root}>


                    <Grid container spacing={1} direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch">

                        {this.SortedProjectList.map(function (data, idx) { return (<Grid key={data.title} item xs={4}><ProjectCard {...data} /></Grid>) })}

                    </Grid>
                </div>
            </section>
        );
    }
}

import MouseEvent from './Input/MouseListener'
import KeyEvent from './Input/KeyListener'
import FSM from './StateMachine'

import GLR from './GL/GLRenderer';

class Game {
	constructor() {

	}

	init = () => {
		GLR.init();
		console.log("Game has been initialised");
		FSM.setState(FSM.states.play);
		FSM.logState();
	}


	update = () => {
		GLR.update();
	}

	render = (dt) => {

		GLR.render();
	}
}

const GameInstance = new Game();

export default GameInstance;

const fragment = `
precision mediump float;
varying vec4 positionOut;

void main(void) {

   gl_FragColor = vec4(positionOut.y * 0.008,1.0 - (positionOut.y * 0.005),0.0,1.0);
   
}`;

export default fragment;
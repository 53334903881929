//import logo from './logo.svg';
import './App.css';

import Particles from 'react-particles-js';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Fragment } from 'react';
import { Link } from 'react-scroll'

import About from './components/About';
import Projects from './components/Projects';
import Header from './components/Header';
import Snake from './components/Snake';

import Cultivation from './components/Cultivation';
import CellularAutomata from './components/CellularAutomata'
import TileEditor from './components/TileEditor';
import EmptyProject from './components/EmptyProject';
import TurnBased from './components/TurnBased';

import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import CssBaseline from '@mui/material/CssBaseline';

import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { darkTheme, lightTheme } from "./components/Theme"
import { darkModeContext } from "./components/ThemeContext"
import AudioVisualiser from './components/AudioVisualiser';

const App = (props) => {

	const DarkModeContext = React.useContext(darkModeContext);

	const { darkMode, setDarkMode } = DarkModeContext

	const classes = props;

	/*
::-webkit-scrollbar-thumb {
	background-color: #424242 !important;
}
::-webkit-scrollbar {
	background-color: transparent !important
}
::-webkit-scrollbar {
	width: 0;
	color: transparent;
}
	*/

	React.useEffect(() => {
		const scrollbar = `
		 ::-webkit-scrollbar {
			height: 16px;
			width: 16px;
		 background: ##202324;
		 color: #aba499;
		 }
		 ::-webkit-scrollbar-thumb {
			background: #454a4d;
			border-radius: 0px;
		 }
		 ::-webkit-scrollbar-corner {
			background: #FFFFFF;
			border-radius: 20px;
		 } 
		 `;

		var css = document.createElement('style');
		if (css.styleSheet) css.styleSheet.cssText = scrollbar;
		else css.appendChild(document.createTextNode(scrollbar));
		
		document.getElementsByTagName("head")[0].appendChild(css);

		const theme = localStorage.getItem("preferred-theme")
		if (theme) {
			const themePreference = localStorage.getItem("preferred-theme")
			console.log("Theme preference is " + themePreference)
			if (themePreference === "dark") {
				setDarkMode(true)
			} else {
				setDarkMode(false)
			}
		} else {
			localStorage.setItem("preferred-theme", "dark")
			setDarkMode(true)
		}
		//eslint-disable-next-line
	}, []);

	const handleThemeChange = () => {
		if (darkMode) {
			localStorage.setItem("preferred-theme", "light")
			setDarkMode(false)
		} else {
			localStorage.setItem("preferred-theme", "dark")
			setDarkMode(true)
		}
	}

	return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={darkMode ? darkTheme : lightTheme} >
                <CssBaseline />
                <BrowserRouter>
                    <div>
                        <Switch>
                            <Route exact path="/" render={props =>
                                <Fragment>
                                    <Link to="Mode" spy={true} smooth={true}>
                                        <Particles canvasClassName="particles"
                                            params={{
                                                "particles": {
                                                    "number": {
                                                        "value": 70,
                                                        "density": {
                                                            "enable": true,
                                                            "value_area": 2000
                                                        }
                                                    },
                                                    "line_linked": {
                                                        "enable": true,
                                                        "opacity": 0.04
                                                    },
                                                    "move": {
                                                        "speed": 0.55
                                                    },
                                                    "size": {
                                                        "value": 2
                                                    },
                                                    "opacity": {
                                                        "anim": {
                                                            "enable": true,
                                                            "speed": 1,
                                                            "opacity_min": 0.09
                                                        }
                                                    }
                                                },
                                                "interactivity": {
                                                    "events": {
                                                        "onclick": {
                                                            "enable": false,
                                                            "mode": "push"
                                                        }
                                                    },
                                                    "modes": {
                                                        "push": {
                                                            "particles_nb": 1
                                                        }
                                                    }
                                                },
                                                "retina_detect": true
                                            }} /></Link>

                                    <div className="App">
                                        <Header />
                                        <div className="App-body">
                                            <div className="App-components" >
                                                <div className="Mode" id="Mode" style={{ textAlign: 'right' }}>
                                                    {darkMode === true ? 'Dark' : 'Light'} Mode
                                                    <IconButton sx={{ ml: 1 }} onClick={handleThemeChange} color="inherit" size="large">
                                                        {darkMode ? <Brightness4Icon /> : <Brightness7Icon />}
                                                    </IconButton>
                                                </div>
                                                <About />
                                                <Projects />
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            } />
                            <Route path="/turn_based" component={TurnBased} />
                            <Route path="/empty_project" component={EmptyProject} />
                            <Route path="/snake" component={Snake} />
                            <Route path="/cultivation" component={Cultivation} />
                            <Route path="/cellular_automata" component={CellularAutomata} />
                            <Route path="/tile_editor" component={TileEditor} />
                            <Route path="/audio_visualiser" component={AudioVisualiser} />
                            <Route component={Error} />
                        </Switch>
                    </div>
                </BrowserRouter>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
